<template>
    <div class="info-financial-support container-fluid" ref="infoFinancialSupportContent">
        <div class="content-blocks" ref="entryContent">
            <div class="row">
                <div class="col text-col" v-html="block.text"></div>
                <div class="col-12 col-md-auto col-lg-3 flex-shrink-0">
                    <a :href="block.link.href" :target="block.link.target || null" class=" btn btn-black btn-square flex items-center align-content-center" v-html="block.link.label"></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InternalLinkReplacementMixin from '@/mixins/InternalLinkReplacementMixin';

export default {
    name: 'InfoFinancialSupport',
    mixins: [InternalLinkReplacementMixin],
    props: ['block'],
    updated() {
        this.$nextTick(() => {
            this.initInternalLinks(this.$refs.infoFinancialSupportContent);
        });
    },
}
</script>

<template>
    <div class="foundation-entry" :class="[entry ? 'foundation-entry-' + entry.type : '']">
        <HeaderPage v-if="!notFound && entry && !entry.noHeader" :title="entry ? entry.title : ''" />
        <div class="foundation entry-content" :class="entry ? 'foundation-' + entry.slug : ''" ref="entryContent">
            <div v-if="entry" class="content-blocks">
                <textLead v-if="entry.lead" class="entry-leadText" :block="{ text: entry.lead }" />
                <div v-if="entry.credits" class="block entry-credits block-text-standard container-fluid">
                    <div class="row content-block-row">
                        <div class="col">
                            <div v-if="entry.credits" class="credits" v-html="entry.credits"></div>
                        </div>
                    </div>
                </div>
                <component v-for="(element, index) in entry.contentBlocks" :key="'element-' + index" :is="element.type" :class="element.backgroundColor ? element.backgroundColor : ''" :block="element"></component>

                <info-financial-support v-if="infoFinancialSupport" :block="infoFinancialSupport" />
            </div>
            <NotFound v-if="notFound" />
            <Spinner v-if="!entry && !notFound" />
        </div>
    </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
// @ is an alias to /src

import { Page } from '@/http/Page';
import Spinner from '@/components/Spinner';
import accordeon from '@/components/contentBlocks/Accordeon';
import imageSingle from '@/components/contentBlocks/ImageSingle';
import textLead from '@/components/contentBlocks/TextLead';
import textStandard from '@/components/contentBlocks/TextStandard';
import text2Cols from '@/components/contentBlocks/Text2Cols';
import videoNative from '@/components/contentBlocks/VideoNative';
import audioNative from '@/components/contentBlocks/AudioNative';
import team from '@/components/contentBlocks/Team';
import newsletter from '@/components/contentBlocks/Newsletter';
import linksProjectJournalEntries from '@/components/contentBlocks/LinksProjectJournalEntries';
import map from '@/components/contentBlocks/Map';
import projectList from '@/components/contentBlocks/ProjectList';
import featuredProjects from '@/components/contentBlocks/FeaturedProjects';
import callToActionEntries from '@/components/contentBlocks/CallToActionEntries';
import coachingEntries from '@/components/contentBlocks/CoachingEntries';
import NotFound from '@/components/NotFound';
import HeaderPage from '@/components/HeaderPage';
import quote from '@/components/contentBlocks/Quote';
import relatedJournalTagCategories from '@/components/contentBlocks/RelatedJournalTagCategories';
import infoDropdown from '@/components/contentBlocks/InfoDropdown';

import InfoFinancialSupport from '@/components/foundation/InfoFinancialSupport';

import InternalLinkReplacementMixin from '@/mixins/InternalLinkReplacementMixin';

export default {
    name: 'Foundation',
    components: {
        Spinner,
        videoNative,
        audioNative,
        textStandard,
        textLead,
        accordeon,
        map,
        imageSingle,
        text2Cols,
        team,
        linksProjectJournalEntries,
        projectList,
        featuredProjects,
        callToActionEntries,
        coachingEntries,
        newsletter,
        NotFound,
        HeaderPage,
        quote,
        relatedJournalTagCategories,
        infoDropdown,
        InfoFinancialSupport
    },
    mixins: [InternalLinkReplacementMixin],
    data() {
        return {
            entry: null,
            notFound: false,
        };
    },
    computed: {
        menu() {
            return this.$store.getters['navigation/get'];
        },
        globalSiteData() {
            return this.$store.getters['general/get'];
        },
        activeMainNavItem() {
            if (this.menu) {
                const activeMainNavItemArr = this.menu.filter((navItems) => navItems.active);
                return activeMainNavItemArr.length ? activeMainNavItemArr[0] : null;
            }
            return null;
        },
        infoFinancialSupport() {
            if (this.entry && this.globalSiteData && this.globalSiteData.infoFinancialSupport && this.globalSiteData.infoFinancialSupport.link.elementId != this.entry.id) {
                // not very elegant, assuming that the title of the parent page of pages which should display the financial support cta will keep it's name
                if (this.activeMainNavItem && this.activeMainNavItem.title && this.activeMainNavItem.title == 'Themen') {
                    return this.globalSiteData.infoFinancialSupport;
                }
            }
            return null
        }
    },
    updated() {
        window.scrollTo(0, 0);
        this.gotoScroll();
        this.$nextTick(() => {
            this.initInternalLinks(this.$refs.entryContent);
        });
    },
    mounted() {
        this.getPage(this.$route.params.pathArr); // new
    },
    activated() {
        if (this.entry && this.entry.metaData) {
            this.initPage();
        }
    },
    methods: {
        async getPage(pathArr) {
            try {
                var response = await Page.get(pathArr);
                this.entry = response.data;
                this.initPage();
            } catch {
                this.notFound = true;
            }
        },
        initPage() {
            if (this.entry.noHeader || false) {
                this.$store.commit('view/setPageClass', 'no-page-header');
            }
            this.$setHeaderData(this.entry.metaData);
        },
        gotoScroll() {
            if (window.location.hash) {
                window.$('html, body').animate(
                    {
                        scrollTop: window.$(window.location.hash).offset().top - 74,
                    },
                    0
                );
            }
        },
    },
};
</script>
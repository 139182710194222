<template>
    <div class="container-fluid content-blocks pt-no-header">
        <div class="not-found-content text-md text-center">
            <p>Die Seite haben wir leider nicht gefunden.</p>
            <p>Da wir eine neue Website haben, sind manche Links veraltet. Aber schauen Sie doch auf unserer <router-link :to="{ name: 'JournalOverview', params: {Locale: $route.params.locale || ''} }">Startseite</router-link> vorbei. Vielleicht werden Sie auch über unsere <router-link :to="{ name: 'Search', params: {Locale: $route.params.locale || ''} }">Suche</router-link> fündig.</p>
        </div>
    </div>
</template>

<script>

export default {
  name: 'NotFound',
}
</script>

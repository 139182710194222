import { createI18n } from 'vue-i18n'

import fr from './fr.json'
import de from './de.json'

export const defaultLocale = 'de'

export const languages = {
    fr: fr,
    de: de,
}

export const messages = Object.assign(languages)


export const i18n = createI18n({
    locale: defaultLocale, // set locale
    fallbackLocale: 'de', // set fallback locale
    messages
})

<template>
    <div class="block block-project-list container-fluid">
        <div v-if="block.sectionHeading" class="row content-block-row">
            <div class="col-12">
                <h2 class="project-section-heading" v-html="block.sectionHeading"></h2>
            </div>
        </div>
        <div v-if="block.projects" class="row content-block-row">
            <div class="col-12 col-md-4 col-lg-3 category-filter">
                <div class="category-filter-heading d-block d-md-none" @click="toggleMobileFilter"><span class="category-filter-toggle" >{{$tc('projects.list.filter.buttonLabel')}}</span></div>
                <div class="category-filter-collapsible collapse d-md-block" :class="{show:showMobileFilter}">
                    <h5 class="category-filter-heading">{{$tc('projects.list.filter.categoriesLevel1')}}</h5>
                    <ul v-if="block.categories" class="category-filter-list categories-level-1">
                        <li v-for="(category, index) in block.categories" :key="'list-item-'+index" @click="setActiveCategoryLevel1(category.id, index)" :class="activeCategoryLevel1 == category.id ? 'active' : ''">{{category.title}} ({{category.count}})</li>
                    </ul>
                    <h5 class="category-filter-heading">{{$tc('projects.list.filter.categoriesLevel2')}}</h5>
                    <ul v-if="activeCategoryLevel1 && block.categories[activeCategoryLevel1Index].childCategories" class="category-filter-list">
                        <li v-if="block.categories[activeCategoryLevel1Index].childCategories.length > 1" :class="!activeCategoryLevel2 ? 'active' : ''" @click="setActiveCategoryLevel2(0)">Alle ({{block.categories[activeCategoryLevel1Index].count}})</li>
                        <li v-for="(category, index) in block.categories[activeCategoryLevel1Index].childCategories" :key="'list-item-'+index" @click="setActiveCategoryLevel2(category.id)" :class="activeCategoryLevel2 == category.id ? 'active' : ''">{{category.title}} ({{category.count}})</li>
                    </ul>
                    <h5 class="category-filter-heading d-md-none">{{$tc('projects.list.sortOptionsLabel')}}</h5>
                    <ul v-if="activeCategoryLevel1 && block.categories[activeCategoryLevel1Index].childCategories" class="category-filter-list d-md-none">
                        <li @click="sortProjectList('title')" :class="{active: projectSortKey == 'title'}">{{$tc('projects.project')}}</li>
                        <li @click="sortProjectList('organization')" :class="{active: projectSortKey == 'organization'}">{{$tc('projects.organization')}}</li>
                        <li @click="sortProjectList('period')" :class="{active: projectSortKey == 'period'}">{{$tc('projects.period')}}</li>
                        <li @click="sortProjectList('amountSortValue')" :class="{active: projectSortKey == 'amountSortValue'}">{{$tc('projects.amount')}}</li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-8 col-lg-6">
                <div>
                    <div class="project-list-header row">
                        <div>
                            <input class="project-search-input form-control" type="text" v-model="search" :placeholder="$tc('projects.list.searchPlaceHolder')"/>
                        </div>
                        <div class="list-sort-options d-none d-md-block col">
                            {{$tc('projects.list.sortOptionsLabel')}}:
                            <span class="sort-option" @click="sortProjectList('title')" :class="{active: projectSortKey == 'title'}">{{$tc('projects.project')}}</span> | <span class="sort-option" @click="sortProjectList('organization')" :class="{active: projectSortKey == 'organization'}">{{$tc('projects.organization')}}</span>
                        </div>
                        <div class="info-col col-2 d-none d-lg-block">
                            <span class="sort-option" @click="sortProjectList('period')" :class="{active: projectSortKey == 'period'}">{{$tc('projects.period')}}</span>
                        </div>
                        <div class="info-col col-2 d-none d-lg-block">
                            <span class="sort-option" @click="sortProjectList('amountSortValue')" :class="{active: projectSortKey == 'amountSortValue'}">{{$tc('projects.amount')}}</span>
                        </div>
                        <div class="col-auto icon-col d-none d-lg-block">

                        </div>
                    </div>
                    <div class="project-list">
                        <template v-if="displayedFilteredProjects.length" lang="html">
                            <div v-for="(project, id) in displayedFilteredProjects" :key="'project-item-'+id" class="project-list-item">
                                <div class="project-header row" @click="toggleProject(project.id)" :class="{ active: activeProjects[project.id] }">
                                    <div class="col">
                                        <h4>{{project.title}}</h4>
                                        <h5>{{project.organization}}</h5>
                                    </div>
                                    <div class="info-col col-2 d-none d-lg-block">
                                        {{project.period}}
                                    </div>
                                    <div class="info-col col-2 d-none d-lg-block text-nowrap">
                                        CHF {{project.amount}}
                                    </div>
                                    <div class="col-auto icon-col">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.945" height="8.68" viewBox="0 0 15.945 8.68">
                                            <path id="Pfad_2456" data-name="Pfad 2456" d="M-23283.258-4872.655l7.625,7.619,7.613-7.619" transform="translate(23283.611 4873.009)" fill="none" stroke="#a0a0a0" stroke-width="1"/>
                                        </svg>
                                    </div>
                                </div>
                                <div class="project-detail collapse" :class="{ show: activeProjects[project.id] }">
                                    <div class="row d-lg-none">
                                        <div class="col-3">
                                            {{$tc('projects.period')}}
                                        </div>
                                        <div class="col-9">
                                            {{project.period}}
                                        </div>
                                        <div class="col-3">
                                            {{$tc('projects.amount')}}
                                        </div>
                                        <div class="col-9">
                                            {{project.amount}}
                                        </div>
                                    </div>
                                    <div class="project-description" :class="{'has-featured-project-link': project.featuredProjectSlug}">
                                        <p>{{project.description}}
                                        <router-link v-if="project.featuredProjectSlug" :to="{ name: 'FeaturedProjectEntry', params: { slug: project.featuredProjectSlug, locale: $route.params.locale || '' } }" class="featured-project-link">{{$tc('projects.list.featuredProjectLink')}}</router-link></p>
                                    </div>

                                </div>
                            </div>

                            <div v-if="displayedProjectsLimit < filteredProjects.length" class="project-list-load-more">
                                <span class="load-more-btn" @click="loadMoreProjects">
                                    {{$tc('projects.list.loadMore')}}<br />
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.045 17.612">
                                        <g fill="none" stroke="currentColor" stroke-width="1.4" data-name="Group 4457" opacity="1">
                                            <path d="M8.523 0v16.622" data-name="Line 2552" />
                                            <path d="m.494 8.594 8.027 8.028 8.029-8.028" data-name="Path 2864" />
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </template>
                        <p v-else class="no-projects-found">{{$tc('projects.list.noProjectsFound')}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="row content-block-row">
            <div class="col col-centered">
                <text-standard :block="{ text: '<p>' + $tc('noEntriesFound') + '</p>' }"></text-standard>
            </div>
        </div>
    </div>
</template>

<script>
import orderBy from 'lodash.orderby';

export default {
    name: 'projectList',
    props: ['block'],
    data () {
        return {
            activeCategoryLevel1: 0,
            activeCategoryLevel1Index: 0,
            activeCategoryLevel2: 0,
            displayedProjectsPerPage: 20,
            displayedProjectsLimit: 20,
            projectSortKey: 'title',
            projectSortDirection: 'asc',
            activeProjects: {},
            animateProjectToggle: true,
            showMobileFilter: false,
            search: '',
        };
    },
    computed: {
        filteredProjects() {
            if (this.activeCategoryLevel1) {
                return this.block.projects.filter((project) => {
                    var show = project.categoriesLevel1.includes(parseInt(this.activeCategoryLevel1));
                    if (this.activeCategoryLevel2) {
                        show = project.categoriesLevel2.includes(parseInt(this.activeCategoryLevel2));
                    }
                    if (show && this.search != '') {
                        show = Object.values(project).join(' - ').toLowerCase().includes(this.search.toLowerCase());
                    }
                    return show;
                });
            } else {
                return [];
            }
        },
        orderedFilteredProjects() {
            var sortedProjects = null;
            if (this.filteredProjects) {
                if (this.projectSortKey == 'amountSortValue') {
                    var _this = this;
                    sortedProjects = orderBy(this.filteredProjects,
                        function (o) {
                            return new Number(o[_this.projectSortKey]);
                        },
                        [_this.projectSortDirection]);
                } else {
                    sortedProjects = orderBy(this.filteredProjects, this.projectSortKey,[this.projectSortDirection]);
                }
            }
            return sortedProjects;
        },
        displayedFilteredProjects() {
            return this.orderedFilteredProjects.slice(0, this.displayedProjectsLimit);
        },
    },
    mounted(){
        if (this.block.categories) {
            this.activeCategoryLevel1 = this.block.categories[0].id;
        }
    },
    methods: {
        setActiveCategoryLevel1(id, index) {
            this.activeCategoryLevel1 = id;
            this.activeCategoryLevel1Index = index;
            this.displayedProjectsLimit = this.displayedProjectsPerPage;
            this.activeCategoryLevel2 =  0;
            this.closeActiveProjects();
            this.closeMobileFilter();
        },
        setActiveCategoryLevel2(id) {
            this.activeCategoryLevel2 = id;
            this.displayedProjectsLimit = this.displayedProjectsPerPage;
            this.activeProjectTransition = 0;
            this.closeActiveProjects();
            this.closeMobileFilter();
        },
        loadMoreProjects(index) {
            this.displayedProjectsLimit += this.displayedProjectsPerPage;
        },
        sortProjectList(sortKey) {
            if (this.projectSortKey == sortKey) {
                this.projectSortDirection = this.projectSortDirection == 'asc' ? 'desc' : 'asc';
            } else {
                this.projectSortDirection = 'asc';
            }
            this.projectSortKey = sortKey;
            this.closeActiveProjects();
        },
        toggleProject(id) {
            this.activeProjects[id] = !(this.activeProjects[id] || false);
        },
        closeActiveProjects() {
            this.activeProjects = {};
        },
        toggleMobileFilter() {
            this.showMobileFilter = !this.showMobileFilter;
        },
        closeMobileFilter() {
            this.showMobileFilter = !this.showMobileFilter;
        }
    }
}
</script>

<template>
    <header class="header-journal container-fluid">
        <div class="row header-journal-row">
            <div class="col">
                <h2 class="text-base">Journal</h2>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'HeaderJournal',
    props: {
        currentCategory: {
            default: null,
        },
    },
};
</script>

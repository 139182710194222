//import './server/server.js'

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n, defaultLocale } from "./i18n/index.js";
import { VueMasonryPlugin } from "vue-masonry";

import VueMatomo from "vue-matomo";

import Vue3Lottie from "vue3-lottie";
import "vue3-lottie/dist/style.css";

import { ObserveVisibility } from "vue-observe-visibility";

window.$ = window.jQuery = require("jquery");

export const app = createApp(App);

app.config.globalProperties.$push = function (link) {
    if (defaultLocale == i18n.global.locale) {
        router.push(`/${link}`);
    } else {
        router.push(`/${i18n.global.locale}/${link}`);
    }
};

app.config.globalProperties.$replace = function (link) {
    if (defaultLocale == i18n.global.locale) {
        router.replace(`/${link}`);
    } else {
        router.replace(`/${i18n.global.locale}/${link}`);
    }
};

app.config.globalProperties.$url = function (link) {
    if (defaultLocale == i18n.global.locale) {
        return `${link}`;
    } else {
        return `${link}`;
    }
};
app.config.globalProperties.$isTouchDevice = function () {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
};

app.config.globalProperties.$canHoverDevice = function () {
    return matchMedia("(pointer:fine)").matches || matchMedia("(any-hover: hover)").matches; // Primary device or at least one of the connected devices can hover
};
(app.config.globalProperties.$createMetaTag = function (attributes, type) {
    const elementType = type || "meta";
    let meta = document.createElement(elementType);
    for (var key in attributes) {
        meta.setAttribute(key, attributes[key]);
    }
    document.getElementsByTagName("head")[0].appendChild(meta);
    return meta;
}),
    (app.config.globalProperties.$setHeaderData = function (data) {
        if (data.title) {
            const cleanTitle = data.title.replace("&shy;", "");
            document.title = cleanTitle;
            const ogTitle = document.querySelector('meta[property="og:title"]');
            if (ogTitle !== null) {
                ogTitle.content = cleanTitle;
            }
            const twitterTitle = document.querySelector('meta[name="twitter:title"]');
            if (twitterTitle !== null) {
                twitterTitle.content = cleanTitle;
            }
        }
        const url = window.location.href;
        const canonical = document.querySelector('link[rel="canonical"]') || app.config.globalProperties.$createMetaTag({ rel: "canonical" }, "link");
        canonical.href = url;
        const ogUrl = document.querySelector('meta[property="og:url"]') || app.config.globalProperties.$createMetaTag({ property: "og:url" });
        ogUrl.content = url;

        if (data.description) {
            const description = (data.description.length > 200 ? data.description.substr(0, 200) + "..." : data.description).replace(/(<([^>]+)>)/gi, " ");
            const metaDescription = document.querySelector('meta[name="description"]') || app.config.globalProperties.$createMetaTag({ name: "description" });
            metaDescription.content = description;
            const ogDescription = document.querySelector('meta[property="og:description"]') || app.config.globalProperties.$createMetaTag({ property: "og:description" });
            ogDescription.content = description;
            const twitterDescription = document.querySelector('meta[name="twitter:description"]') || app.config.globalProperties.$createMetaTag({ name: "twitter:description" });
            twitterDescription.content = description;
        }
        if (data.image) {
            const ogImage = document.querySelector('meta[property="og:image"]') || app.config.globalProperties.$createMetaTag({ property: "og:image" });
            ogImage.content = data.image;
            const twitterImage = document.querySelector('meta[name="twitter:image"]') || app.config.globalProperties.$createMetaTag({ name: "twitter:image" });
            twitterImage.content = data.image;
        }

        // Add No index tag for hidden entries
        const robots = document.querySelector('meta[property="robots"]') || app.config.globalProperties.$createMetaTag({ property: "robots" });
        robots.content = (data.noIndex || false) ? 'noindex' : 'index';
    });

app.use(store)
    .use(router)
    .use(i18n)
    .use(VueMatomo, {
        host: "https://matomo.stiftung-mercator.ch",
        siteId: 1,
        router: router,
    })
    .use(Vue3Lottie)
    .use(VueMasonryPlugin)
    .directive("observe-visibility", ObserveVisibility)
    .mount("#app");

<template>
    <div class="container-fluid entry-header bg-black d-flex flex-column justify-content-center align-items-center covering-header-offset-element">
        <div class="text-heading-md position-absolute animation-heading">
            <transition name="fade-title">
                <h1 v-show="showTitle && loaded" class="entry-title text-white" v-html="titles && titles[activeTitle]"></h1>
            </transition>
        </div>
        <Vue3Lottie class="blend-animation" v-if="renderLottie && loaded" :animationData="animations[Math.floor(Math.random() * animations.length)]" :autoPlay="true" :delay="activeTitle === 0 ? firstDelay : 0" height="100%" width="35%" :loop="1" @onComplete="forceRerender" />
    </div>
</template>
<script>
import { Vue3Lottie } from 'vue3-lottie';
import line01 from '../assets/animations/line_01.json';
import line02 from '../assets/animations/line_02.json';
import line03 from '../assets/animations/line_03.json';
import line04 from '../assets/animations/line_04.json';
import line05 from '../assets/animations/line_05.json';
import line06 from '../assets/animations/line_06.json';
import line07 from '../assets/animations/line_07.json';
import line08 from '../assets/animations/line_08.json';
import line09 from '../assets/animations/line_09.json';
import line10 from '../assets/animations/line_10.json';
import line11 from '../assets/animations/line_11.json';
import line12 from '../assets/animations/line_12.json';
import line13 from '../assets/animations/line_13.json';
import line14 from '../assets/animations/line_14.json';
import line15 from '../assets/animations/line_15.json';
import line16 from '../assets/animations/line_16.json';
export default {
    components: {
        Vue3Lottie,
    },
    props: {
        titles: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {
            animations: [line01, line02, line03, line04, line05, line06, line07, line08, line09, line10, line11, line12, line13, line14, line15, line16],
            active: 0,
            activeTitle: 0,
            showTitle: true,
            renderLottie: true,
            firstDelay: 1000,
            loaded: false,
        };
    },
    mounted() {
        this.loaded = true;
    },
    methods: {
        forceRerender() {
            this.showTitle = false;
            this.firstDelay = 0;
            setTimeout(() => {
                if (this.activeTitle < this.titles.length - 1) {
                    this.activeTitle++;
                } else {
                    this.activeTitle = 0;
                }
                this.showTitle = true;
            }, 1000);
            setTimeout(() => {
                this.renderLottie = false;
                this.$nextTick(() => {
                    this.renderLottie = true;
                });
            }, 1500);
        },
    },
};
</script>
<style scoped>
.blend-animation {
    mix-blend-mode: exclusion;
}
.fade-title-enter-active,
.fade-title-leave-active {
    transition: opacity 0.5s ease;
}
.fade-title-enter-from,
.fade-title-leave-to {
    opacity: 0;
}
</style>

<template>
    <div
        v-for="(entry, x) in entries"
        :key="x"
        v-observe-visibility="{
            callback: (isVisible, entry) => visibilityChanged(isVisible, entry, x),
            intersection: {
                threshold: 0.9,
                delay: 500,
            },
        }"
    >
        <div class="animation-item container-fluid bg-black d-flex flex-column justify-content-center align-items-center">
            <a :href="entry[2]" class="text-heading-md position-absolute text-center">
                <h1 class="entry-title text-white" v-html="entry[0]"></h1>
                <span class="entry-subtitle" v-html="entry[1]"></span>
            </a>
            <Vue3Lottie @onAnimationLoaded="loaded = true" ref="animation" class="blend-animation" :animationData="animations[x]" :pauseAnimation="playStates[x]" :loop="1" :autoPlay="false" height="100%" width="35%" />
        </div>
    </div>
</template>
<script>
import { Vue3Lottie } from 'vue3-lottie';
import line00 from '../../assets/animations/line_events.json';
import line01 from '../../assets/animations/line_01.json';
import line02 from '../../assets/animations/line_02.json';
import line03 from '../../assets/animations/line_03.json';
export default {
    components: {
        Vue3Lottie,
    },
    props: {
        entries: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            animations: [line00, line01, line02, line03],
            playStates: [true, true, true, true],
            loaded: false,
        };
    },
    methods: {
        visibilityChanged(isVisible, entry, x) {
            if (this.loaded) {
                if (!isVisible) {
                    this.$refs['animation'][x].stop();
                }
                this.playStates[x] = !isVisible;
            }
        },
    },
};
</script>
<style scoped>
.blend-animation {
    mix-blend-mode: exclusion;
}
</style>

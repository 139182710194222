<template>
    <div class="journal-entry" :class="[entry ? 'journal-entry-' + entry.type : '']">
        <template v-if="entry">
            <journal-entry-header :entry="entry" animate-content="true" :show-content="showPageHeaderContent" :key="entry.id"></journal-entry-header>

            <div class="entry-content" ref="entryContent">
                <div class="content-blocks">
                    <textLead v-if="entry.leadText" class="entry-leadText" :block="{ text: entry.leadText }" />

                    <div v-if="entry.credits || entry.postDate" class="block entry-credits block-text-standard container-fluid">
                        <div class="row content-block-row">
                            <div class="col">
                                <div v-if="entry.credits" class="credits" v-html="entry.credits"></div>
                                <div v-if="entry.postDate" class="entry-date" v-html="entry.postDate"></div>
                            </div>
                        </div>
                    </div>
                    <component v-for="(element, index) in entry.contentBlocks" :key="'element-' + index" :is="element.type" :block="element" :theme-color="this.entry && this.entry.theme ? 'theme-custom-colors' : null" :class="element.backgroundColor ? 'bg-' + element.backgroundColor : ''"></component>
                </div>
                <div class="content-blocks">
                    <journal-entry-meta :entry="entry"></journal-entry-meta>
                </div>
            </div>
            <journal-entry-related-articles :entry="entry"></journal-entry-related-articles>
        </template>
        <Spinner v-else />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import JournalEntryHeader from '@/components/journal/JournalEntryHeader';
import JournalEntryMeta from '@/components/journal/JournalEntryMeta';
import JournalEntryRelatedArticles from '@/components/journal/JournalEntryRelatedArticles';
import Spinner from '@/components/Spinner';
import textLead from '@/components/contentBlocks/TextLead';
import accordeon from '@/components/contentBlocks/Accordeon';
import imageSingle from '@/components/contentBlocks/ImageSingle';
import textStandard from '@/components/contentBlocks/TextStandard';
import infoBox from '@/components/contentBlocks/InfoBox';
import images2Cols from '@/components/contentBlocks/Images2Cols';
import imageGallery from '@/components/contentBlocks/ImageGallery';
import divider from '@/components/contentBlocks/Divider';
import quote from '@/components/contentBlocks/Quote';
import person from '@/components/contentBlocks/Person';
import additionalLinks from '@/components/contentBlocks/AdditionalLinks';
import interview from '@/components/contentBlocks/Interview';
import spacer from '@/components/contentBlocks/Spacer';
import youtubeVideo from '@/components/contentBlocks/YoutubeVideo';
import audioNative from '@/components/contentBlocks/AudioNative';
import podcast from '@/components/contentBlocks/Podcast';
import iframe from '@/components/contentBlocks/Iframe';
import imageLink from '@/components/contentBlocks/ImageLink';
import infoDropdown from '@/components/contentBlocks/InfoDropdown';
import mediaTeaser from '@/components/contentBlocks/MediaTeaser';

import { Journal } from '@/http/Journal';
import InternalLinkReplacementMixin from '@/mixins/InternalLinkReplacementMixin';

export default {
    name: 'JournalEntry',
    components: {
        JournalEntryHeader,
        JournalEntryMeta,
        JournalEntryRelatedArticles,
        Spinner,
        textLead,
        textStandard,
        accordeon,
        imageSingle,
        infoBox,
        images2Cols,
        imageGallery,
        divider,
        quote,
        person,
        additionalLinks,
        interview,
        spacer,
        youtubeVideo,
        podcast,
        iframe,
        imageLink,
        audioNative,
        infoDropdown,
        mediaTeaser
    },
    mixins: [InternalLinkReplacementMixin],
    data() {
        return {
            entry: null,
            prevRoute: null,
            pageInitT: false,
            enterTransitionFinished: false,
            showPageHeaderContent: false,
        };
    },
    computed: {
        ...mapGetters({
            view: 'view/get',
        }),
    },
    mounted() {
        this.preparePageContent();
        this.getJournalEntry();
    },
    unmounted() {
        this.resetPageContent();
    },
    activated() {
        this.preparePageContent();
    },
    deactivated() {
        this.resetPageContent();
    },
    updated() {
        this.$nextTick(() => {
            this.initInternalLinks(this.$refs.entryContent);
        });
    },
    methods: {
        async getJournalEntry() {
            try {
                var response = await Journal.get(this.$route.params.slug);
                this.entry = response.data;
                // set Theme
                if (this.enterTransitionFinished) {
                    this.$nextTick(function () {
                        this.initPageContent();
                    });
                }
            } catch {
                this.$push('');
            }
        },
        preparePageContent() {
            if (this.pageInitT) {
                clearTimeout(this.pageInitT);
            }
            var that = this;
            this.pageInitT = setTimeout(function () {
                that.enterTransitionFinished = true;
                if (that.entry) {
                    that.$nextTick(function () {
                        that.initPageContent();
                    });
                }
            }, that.view.transition.duration);
            that.$store.commit('view/setTransitionDuration', 0);
        },
        initPageContent() {
            if (this.entry && this.entry.theme) {
                this.$el.classList.add('theme-custom-colors');
                this.$store.commit('view/setPageClass', 'theme-custom-colors');
                this.$store.commit('view/setPageCssVars', this.entry.theme);
            }
            if (this.entry && this.entry.metaData) {
                this.$setHeaderData(this.entry.metaData);
            }
            this.showPageHeaderContent = true;
        },
        resetPageContent() {
            this.enterTransitionFinished = false;
            this.showPageHeaderContent = false;
            if (this.pageInitT) {
                clearTimeout(this.pageInitT);
                this.pageInitT = false;
            }
            if (this.entry && this.entry.theme) {
                this.$el.classList.remove('theme-custom-colors');
            }
        },
        returnToJournal() {
            if (this.prevRoute) {
                this.$router.go(-1);
            } else {
                this.$router.push({ name: 'JournalOverview', params: { locale: this.$route.params.locale || '' } });
            }
        },
    },
};
</script>

<template>
    <div class="foundation-entry">
        <div class="foundation entry-content" ref="entryContent">
            <NotFound />
        </div>
    </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
// @ is an alias to /src

import NotFound from '@/components/NotFound';

import InternalLinkReplacementMixin from '@/mixins/InternalLinkReplacementMixin';

export default {
    name: 'Foundation',
    components: {
        NotFound,
    },
    mixins: [InternalLinkReplacementMixin],
    data() {
        return {
            entry: null,
            notFound: false,
        };
    },
    computed: {},
    updated() {
        this.$nextTick(() => {
            this.initInternalLinks(this.$refs.entryContent);
        });
    },
    mounted() {},
    activated() {
        this.initPage();
    },
    methods: {
        initPage() {
            this.$setHeaderData({
                title: '404 - Seite nicht gefunden',
                description: '',
                image: null,
                noIndex: true
            });
        },
    },
};
</script>
<template>
    <div class="block preview-block block-newsletter" ref="container">
        <div
            ref="signupText"
            id="signupText"
            :class="{
                final: signupTextClass.final,
                moved: signupTextClass.moved,
            }"
        >
            <div ref="innerMover" id="innerMover">Newsletter</div>
        </div>
        <form @submit="subscribe" method="post">
            <div
                ref="formContainer"
                id="formContainer"
                :style="calcFormContainerStyle"
                :class="{
                    visible: formContainerClass.visible,
                    open: formContainerClass.open,
                    showInputs: formContainerClass.showInputs,
                }"
            >
                <input type="text" v-model="name" name="name" :placeholder="$tc('newsletter.name')" style="display: none !important" />
                <input type="email" v-model="email" name="email" required :placeholder="$tc('newsletter.email')" />
            </div>
            <div
                id="buttonAndMessage"
                ref="buttonAndMessage"
                :class="{
                    visible: buttonAndMessageClass.visible,
                    final: buttonAndMessageClass.final,
                }"
            >
                <input :disabled="disableButton" type="submit" :value="getStatus()" />
                <span v-if="loading" class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
            </div>
        </form>
        <div class="mobileTester" ref="mobileTester"></div>
        <canvas ref="canvas"></canvas>
    </div>
</template>

<script>
import { Newsletter } from '@/http/Newsletter';

import { drawPath, pathManager } from '../animationContent/lineDrawer';

/* webpackIgnore: true */ import newsmall from '../animationContent/signupPaths/new/small.data';
/* webpackIgnore: true */ import newbig from '../animationContent/signupPaths/new/big.data';
export default {
    name: 'NewsletterForm',
    components: {},
    props: {},
    data() {
        return {
            name: null,
            email: null,
            loading: false,
            thickness: 20,
            formSent: false,
            canChangeSignupButton: false,
            formContainerClass: {
                visible: false,
                open: false,
                showInputs: false,
                width: -1,
                display: false,
            },
            buttonAndMessageClass: {
                visible: false,
            },
            signupTextClass: {
                final: false,
                moved: false,
            },
        };
    },
    computed: {
        globalSiteData() {
            return this.$store.getters['general/get'];
        },
        disableButton() {
            return this.loading || this.formSent;
        },
        calcFormContainerStyle() {
            var styleObject = {
                display: this.formContainerClass.display ? 'block' : 'none',
            };

            if (this.formContainerClass.width != -1) {
                styleObject.width = this.formContainerClass.width + 'px';
            }
            return styleObject;
        },
    },
    mounted() {
        this.canvas = this.$refs.canvas;
        this.ctx = this.canvas.getContext('2d');
        this.div = this.$refs.container;
        this.div.addEventListener('mouseenter', this.start.bind(this));
        this.div.addEventListener('mouseleave', this.finish.bind(this));
        this.startMoment = 0;
        this.endMoment = 0;
        this.finished = false;
        this.signupText = this.$refs.signupText;
        this.formContainer = this.$refs.formContainer;

        this.fieldWidth = 0;

        this.paths = new pathManager([newsmall, newbig], [600]);
        setTimeout(this.resize.bind(this), 20);
        window.addEventListener('resize', this.resize.bind(this));
        this.getThickness();
        this.render();
    },
    methods: {
        async subscribe(e) {
            e.preventDefault();
            this.loading = true;
            var response = await Newsletter.send(this.email);
            this.loading = false;
            this.email = null;
            this.name = null;
            this.formSent = true;
            this.startMoment = new Date().getTime();
            this.fieldWidth = this.formContainer.getBoundingClientRect().width;
            this.formContainer.style.transition = 'unset';
        },
        getThickness() {
            var mobileTest = window.getComputedStyle(this.$refs.mobileTester).getPropertyValue('display');
            if (mobileTest == 'block') return 30;
            else return 20;
        },
        render() {
            var thickness = this.thickness;
            this.ctx.clearRect(0, 0, this.w, this.h);
            var path = this.paths.getPath(this.thickness == 30 ? 1000 : 400);
            if (!this.finished) {
                if (this.startMoment && path) {
                    this.ctx.fillStyle = 'black';
                    var delay = 500;
                    var fullTime = 1500;
                    var t = new Date().getTime() - this.startMoment;
                    var startPoint = Math.ease((t - delay) / fullTime);
                    var endPoint = Math.ease(t / fullTime);
                    drawPath(this.w, this.h, this.ctx, path, 0, startPoint, endPoint, 0, thickness);

                    if (t > fullTime * 0.8 && t < fullTime) {
                        this.signupTextClass.moved = true;
                    }
                    if (t > fullTime && t < fullTime + 200) {
                        this.formContainerClass.display = true;
                    }
                    if (t > fullTime + 200) {
                        this.formContainerClass.open = true;
                        this.buttonAndMessageClass.visible = true;
                    }

                    if (t > fullTime + 300) {
                        this.formContainerClass.showInputs = true;
                    }

                    if (t > fullTime + 400) {
                        this.finished = true;
                    }
                } else {
                    //if (this.offsetTop - window.scrollY < window.innerHeight / 2) {
                    this.start();
                    //}
                }
            }

            if (this.formSent) {
                this.ctx.fillStyle = 'black';
                var delaySent = 150;
                var timeSent = 300;
                var fieldCloseTime = 300;
                var t2 = new Date().getTime() - this.startMoment;

                if (t2 < fieldCloseTime) {
                    var www = Math.ease(t2 / fieldCloseTime) * (2 * thickness - this.fieldWidth) + this.fieldWidth;
                    this.formContainerClass.width = www;
                } else if (t2 < fieldCloseTime + timeSent + delaySent) {
                    var startPoint2 = Math.ease((t2 - (delaySent + fieldCloseTime)) / timeSent);
                    var endPoint2 = Math.ease((t2 - fieldCloseTime) / timeSent);
                    this.formContainerClass.display = false;
                    this.signupTextClass.final = true;
                    this.canChangeSignupButton = true;
                    drawPath(this.w, this.h, this.ctx, path, 1, startPoint2, endPoint2, 0, thickness);
                    if (t2 > fieldCloseTime + delaySent) {
                        this.buttonAndMessageClass.final = true;
                    }
                }
            }
            requestAnimationFrame(this.render);
        },

        getStatus() {
            return this.formSent && this.canChangeSignupButton ? 'Danke für ihre Anmeldung' : this.$tc('newsletter.submit');
        },
        resize() {
            this.box = this.div.getBoundingClientRect();
            this.w = this.box.width;
            this.h = this.box.height;
            this.canvas.width = this.w * 2;
            this.canvas.height = this.h * 2;
            this.ctx.scale(2, 2);
            this.paths.load(this.w);
            this.thickness = this.getThickness();

            this.offsetTop = this.box.top + window.scrollY + this.box.height / 2;
        },
        start() {
            if (!this.startMoment && !this.endMoment) {
                this.endMoment = 0;
                this.startMoment = new Date().getTime();
            }
        },
        finish() {
        },
    },
};
</script>

<style scoped lang="scss">
.mobileTester {
    display: none;
    pointer-events: none;
}
@media screen and (min-width: 600px) {
    .mobileTester {
        display: block;
    }
}
canvas {
    width: 100%;
    position: absolute !important;
    top: 0px;
    left: 0px;
    pointer-events: none;
}
.preview-block {
    position: relative;
    --bs-aspect-ratio: unset;
    * {
        --bs-aspect-ratio: unset;
    }
}
.preview-block {
    height: 30vw;
    min-height: 300px;
}

#signupText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 28px;
    width: unset;
    height: unset;
    line-height: 1em;
    opacity: 1;
    transition: opacity 400ms;
    #innerMover {
        transition: transform 500ms;
        transform: translateY(0);
    }
    &.final {
        opacity: 0;
    }
}
#signupText.moved #innerMover {
    transform: translateY(calc(-70px));
}
#formContainer {
    border-radius: 20px;
    height: 40px;
    background-color: black;
    width: 100%;
    max-width: 40px;
    padding: 0px;
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    text-align: center;
    transition: all 500ms;

    input {
        display: none;
        color: white;
    }

    &.showInputs input {
        display: inline-block;
    }
    &.open {
        width: 100%;
        max-width: 300px;
    }
}

#buttonAndMessage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50px);
    opacity: 0;
    transition: opacity 400ms, transform 500ms;
    text-align: center;
}

#buttonAndMessage.visible {
    opacity: 1;
}

#buttonAndMessage.final {
    transform: translate(-50%, -50%);
    input {
        pointer-events: none;
    }
}

textarea:focus,
input:focus {
    outline: none;
}
input[type='text'] {
    display: none;
}
input[type='email'] {
    border: 0px;
    padding: 0px;
    font-size: 16px;
    line-height: 40px;
    height: 40px;
    width: 100%;
    padding: 0px 20px;
    box-sizing: border-box;
    background-color: transparent;
    font-family: 'Helvetica LT Pro';
}

input[type='submit'] {
    color: white;
    border: 0px;
    font-size: 16px;
    background-color: transparent;
    font-family: 'Helvetica LT Pro';
}
input[type='submit'] {
    cursor: pointer;
}
@media screen and (min-width: 600px) {
    #signupText.moved #innerMover {
        transform: translateY(calc(-93px));
    }

    #signupText {
        font-size: 35px;
    }

    #formContainer {
        border-radius: 30px;
        height: 60px;
        width: 60px;
        &.open {
            max-width: 480px;
        }
    }
    #buttonAndMessage {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 76px);
    }

    input[type='email'],
    input[type='submit'] {
        font-size: 20px;
        width: 100%;
        padding: 0px 30px;
        box-sizing: border-box;
        background-color: transparent;
    }
    input[type='email'] {
        line-height: 80px;
        height: 60px;
    }

    input[type='submit'] {
        cursor: pointer;
        margin-top: unset;
        line-height: unset;
    }
}
</style>

<template>
    <router-link :to="{ name: 'EventEntry', params: { slug: entry.slug, locale: $route.params.locale || '' } }" class="block-preview-call-to-action-entry container-fluid" :class="entryThemeClass" :style="cssStyle">
        <h5 v-if="entry.text && entry.text.length" class="entry-preview-subtitle" v-html="entry.text"></h5>
        <h2 class="entry-preview-title" v-html="entry.title"></h2>
    </router-link>
</template>

<script>
export default {
    name: "PreviewBlockCallToActionEntry",
    //components: {TextPreviewBlock},
    props: ["entry"],
    data() {
        return {};
    },
    computed: {
        cssStyle() {
            if (this.entry && this.entry.theme) {
                const secondaryColor = this.entry.theme.secondaryColor || '#FFF';
                return {
                    '--theme-primary-color': this.entry.theme.primaryColor || '#000',
                    '--theme-secondary-color': secondaryColor
                }
            } else {
                return null;
            }
        },
        entryThemeClass() {
            let themeClass = 'theme-default';
            if (this.entry && this.entry.theme) {
                themeClass = 'theme-custom-colors';
            }
            return themeClass;
        },
    },
    methods: {},
};
</script>

<template :key="categorySlug || 'default'">
    <div class="journal-overview" :key="categorySlug || 'default'">
        <HeaderPage :title="currentCategoryName" :show-animation="false" />
        <div class="entry-content data-container" :class="{'loading-data': !data}">
            <header class="header-journal container-fluid" @click="onHeaderClick">
                <div class="row header-journal-row">
                    <div class="col">
                        <h2 class="text-base"><span v-if="data">{{ data.length }} {{$tc('journal.categoryArchive.entriesIn')}}</span> <router-link :to="{ name: 'JournalOverview', params: {locale: $route.params.locale || '', slug:'xx'} }">Journal</router-link></h2>
                    </div>
                </div>
            </header>
            <journal-preview-blocks :entries="data" :limit="limit" :class="{'has-load-more': (showLoadMore && data.length > limit)}"></journal-preview-blocks>
            <div v-if="showLoadMore && data.length > limit" class="journal-load-more" @click="limit = limit + 12">
                mehr laden<br />
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.045 17.612">
                    <g fill="none" stroke="currentColor" stroke-width="1.4" data-name="Group 4457" opacity="1">
                        <path d="M8.523 0v16.622" data-name="Line 2552" />
                        <path d="m.494 8.594 8.027 8.028 8.029-8.028" data-name="Path 2864" />
                    </g>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Journal } from '@/http/Journal';
import JournalPreviewBlocks from '@/components/journal/JournalPreviewBlocks';
import HeaderPage from '@/components/HeaderPage';

export default {
    name: 'JournalCategoryArchive',
    components: {
        JournalPreviewBlocks,
        HeaderPage,
    },
    props: {
        categorySlug: {
            default: false,
        },
    },
    data() {
        return {
            data: null,
            metaData: null,
            limit: 12,
            showLoadMore: false,
        };
    },
    computed: {
        ...mapGetters({
            'view':'view/get',
        }),
        currentCategoryName() {
            if (this.metaData && typeof this.metaData.pageTitle !== 'undefined') {
               return this.metaData.pageTitle;
            } else {
                return '';
            }
        },
    },
    mounted() {
        this.initPageContent();
        this.getJournalEntries();
    },
    unmounted() {
        this.resetPageContent();
    },
    activated() {
        this.initPageContent();
        //this.$store.commit('journalCategories/setCurrentCategorySlug', this.categorySlug);
        if (this.metaData) {
            this.$setHeaderData(this.metaData);
        }
    },
    deactivated() {
        this.resetPageContent();
        //this.$store.commit('journalCategories/setCurrentCategorySlug', false);
    },
    methods: {
        async getJournalEntries() {
            try {
                let response;
                if (this.categorySlug) {
                    response = await Journal.getEntriesByTaxonomy('category', 'journalTagCategories', this.categorySlug);
                } else {
                    response = [];
                }

                this.data = response.data.data;
                this.metaData = response.data.meta;
                // set header data
                if (this.metaData) {
                    this.$setHeaderData(this.metaData);
                }
                this.showLoadMore = true;
            } catch {
                /**/
            }
        },
        /*async getJournalStampEntries() {
            try {
                let response;
                response = await Journal.getAllJournalEvents();

                this.stampEntriesData = response.data.data;
            } catch {
                //
            }
        },*/
        initPageContent() {
            this.$store.commit('view/setPageClass', 'theme-dark');
        },
        resetPageContent() {
            //document.body.classList.remove('theme-dark');
        }
    },
};
</script>

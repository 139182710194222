<template>
  <nav class="main-navigation">
    <ul class="ul-level-0">
        <li v-for="mainElement, index in menu" :key="index" :class="{ 'active': mainElement.active }">
            <router-link v-if="mainElement.link" :to="$url(mainElement.link)" :title="mainElement.title" @click="onNavLinkClicked">{{ mainElement.title }}</router-link>
            <a v-else href="#" :title="mainElement.title" @click="onDropDownLinkClicked($event, index)">{{ mainElement.title }}</a>

            <ul class="ul-level-1" v-if="mainElement.children && mainElement.active">
                <li v-for="subElement in mainElement.children" v-bind:key="subElement" :class="{ 'active': subElement.active }">
                    <router-link :to="$url(subElement.link)" :title="subElement.title"  @click="onNavLinkClicked">{{ subElement.title }}</router-link>
                </li>
            </ul>
        </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'


export default {
    name: 'MainNavigation',
    props: [],
    data () {
        return {
            activeChildNav: null,
            activeItem: null
        };
    },
    computed: {
        ...mapGetters({
            'menu':'navigation/get',
        }),
    },
    components: {

    },
    methods: {
        onNavLinkClicked(element) {
            this.$emit('closeMenu');
        },
        onDropDownLinkClicked(e, index) {
            e.preventDefault();
            this.$store.commit("navigation/setMenuItemActiveByIndex", index);
        }
    }
}
</script>

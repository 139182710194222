<template :key="categorySlug || 'default'">
    <div v-if="entries" class="container-fluid preview-blocks content-blocks">
        <div id="journalPreview-masonry" v-if="entries.length" class="preview-blocks-row row masonry-container" v-masonry="journalPreview" transition-duration="0.3s" item-selector=".item" percent-position="true">
            <div v-masonry-tile class="preview-block-col col-md-6 col-lg-4 item" v-for="(entry, index) in entries.slice(0, limit ? limit : entries.length)" :key="index">
                <previewBlockCallToActionEntry v-if="entry.type == 'callToActionEntry'" :entry="entry" @click="openJournalArticle($event, getEntryThemeClass(entry))"></previewBlockCallToActionEntry>
                <previewBlockCoachingEntry v-else-if="entry.type == 'coachingEntry'" :entry="entry" @click="openJournalArticle($event, getEntryThemeClass(entry))"></previewBlockCoachingEntry>
                <previewBlockDefaultEntry v-else :entry="entry" @click="openJournalArticle($event, getEntryThemeClass(entry))"></previewBlockDefaultEntry>
            </div>
        </div>

        <div v-else class="row content-block-row">
            <div class="col col-centered">
                <text-standard :block="{ text: '<p>' + $tc('noEntriesFound') + '</p>' }"></text-standard>
            </div>
        </div>
        <div v-if="showLeaveTransitionOverlay" class="leave-transition-overlay" :class="{ cover: animateLeaveTransitionOverlay }" :style="leaveTransitionOverlayStyle"></div>
    </div>
    <Spinner v-else />
</template>

<script>
import Spinner from '@/components/Spinner';
import textStandard from '@/components/contentBlocks/TextStandard';
import previewBlockDefaultEntry from '@/components/previewBlocks/DefaultEntry';
import previewBlockCallToActionEntry from '@/components/previewBlocks/CallToActionEntry';
import previewBlockCoachingEntry from '@/components/previewBlocks/CoachingEntry';

export default {
    name: 'JournalPreviewBlocks',
    components: {
        Spinner,
        textStandard,
        previewBlockDefaultEntry,
        previewBlockCallToActionEntry,
        previewBlockCoachingEntry,
    },
    props: {
        entries: null,
        /*scrollContainer: {
            default: document,
        },*/
        limit: {
            default: 0,
        },
        displayStamp: {
            default: false,
        },
        stampEntries: null,
    },
    computed: {
        hasStamp() {
            return this.stampEntries && this.stampEntries.length;
        },
    },
    data() {
        return {
            minWidthCol3Layout: 1280,
            minWidthCol2Layout: 768,
            showLeaveTransitionOverlay: false,
            animateLeaveTransitionOverlay: false,
            leaveTransitionOverlayStyle: {},
            //stampIndex: 2,
        };
    },
    watch: {
        stampIndex() {
            this.$nextTick(() => {
                this.$redrawVueMasonry();
            });
        },
    },
    mounted() {
        this.resetLeaveTransitionOverlay();
    },
    unmounted() {
        this.resetLeaveTransitionOverlay();
    },
    activated() {
        this.resetLeaveTransitionOverlay();
        window.dispatchEvent(new Event('resize'));
    },
    deactivated() {
    },
    methods: {
        openJournalArticle(e, theme) {
            var tempMs = Date.now();
            if (!this.showLeaveTransitionOverlay) {
                this.$store.commit('view/setTransitionDuration', 900);
                const currentTarget = e.currentTarget;
                var boundingClientRect = currentTarget.getBoundingClientRect();
                this.leaveTransitionOverlayStyle = {
                    backgroundColor: window.getComputedStyle(currentTarget).backgroundColor,
                    left: boundingClientRect.left + 'px',
                    right: window.innerWidth - boundingClientRect.right + 'px',
                    top: boundingClientRect.top + 'px',
                    bottom: window.innerHeight - boundingClientRect.bottom + 'px',
                };
                this.showLeaveTransitionOverlay = true;
                var that = this;
                setTimeout(function () {
                    that.leaveTransitionOverlayStyle.left = 0;
                    that.leaveTransitionOverlayStyle.top = 0;
                    that.leaveTransitionOverlayStyle.right = 0;
                    that.leaveTransitionOverlayStyle.bottom = 0;
                }, 20);
                setTimeout(function () {
                    if (!that.$store.getters['view/get'].pageClass.includes(theme)) {
                        // ios workaround timing problem pageClass: do not set transparent class if theme color already is set
                        that.$store.commit('view/setPageClass', theme + ' transparent-header');
                    }
                }, 900);
            }
        },
        resetLeaveTransitionOverlay() {
            this.showLeaveTransitionOverlay = false;
            this.animateLeaveTransitionOverlay = false;
            this.leaveTransitionOverlayStyle = {};
        },
        getEntryThemeClass(entry) {
            let themeClass = 'theme-default';
            if (entry && entry.theme) {
                themeClass = 'theme-custom-colors';
            }
            return themeClass;
        },
    },
};
</script>

<style scoped>
.leave-transition-overlay {
    position: fixed;
    transition: all 880ms ease;
    z-index: 1500;
}
</style>

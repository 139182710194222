<template :key="categorySlug || 'default'">
    <div class="journal-overview" :key="categorySlug || 'default'">
        <header-journal-overview :titles="globalSiteData && globalSiteData.animationTexts" />
        <div class="entry-content data-container" :class="{'loading-data': !data}">
            <header-journal ref="headerJournal" :current-category="categorySlug"></header-journal>
            <journal-preview-blocks :entries="data" :limit="limit" :class="{'has-load-more': (showLoadMore && data.length > limit)}"></journal-preview-blocks>
            <div v-if="showLoadMore && data.length > limit" class="journal-load-more" @click="limit = limit + 12">
                mehr laden<br />
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.045 17.612">
                    <g fill="none" stroke="currentColor" stroke-width="1.4" data-name="Group 4457" opacity="1">
                        <path d="M8.523 0v16.622" data-name="Line 2552" />
                        <path d="m.494 8.594 8.027 8.028 8.029-8.028" data-name="Path 2864" />
                    </g>
                </svg>
            </div>
        </div>
        <template v-if="globalSiteData && globalSiteData.eventTeaser && globalSiteData.eventTeaser.length">
            <header class="header-journal-event-teaser container-fluid">
                <div class="row header-journal-row">
                    <div class="col">
                        <h2 class="text-base">{{ $tc("journal.overview.headerEventTeaser") }}</h2>
                    </div>
                </div>
            </header>
            <journal-event-teaser :entries="globalSiteData.eventTeaser"></journal-event-teaser>
            <div v-if="globalSiteData.indexPageEventsUri && globalSiteData.indexPageEventsUri.length" class="footer-journal-event-teaser container-fluid">
                <router-link :to="globalSiteData.indexPageEventsUri">{{ $tc("journal.overview.showAllEvents") }}</router-link>
            </div>
        </template>
        <template v-if="scrollAnimations && scrollAnimations.length">
            <header class="header-journal-intro-animations container-fluid">
                <div class="row header-journal-row">
                    <div class="col">
                        <h2 class="text-base">{{ $tc("journal.overview.headerIntroAnimations") }}</h2>
                    </div>
                </div>
            </header>
            <div class="journal-intro-animations">
                <journal-intro-animations :entries="scrollAnimations"></journal-intro-animations>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Journal } from '@/http/Journal';
import HeaderJournal from '@/components/HeaderJournal';
import JournalPreviewBlocks from '@/components/journal/JournalPreviewBlocks';
import HeaderJournalOverview from '@/components/HeaderJournalOverview';
import JournalIntroAnimations from '@/components/journal/JournalIntroAnimations';
import JournalEventTeaser from '@/components/journal/JournalEventTeaser';

export default {
    name: 'JournalOverview',
    components: {
        JournalPreviewBlocks,
        HeaderJournal,
        HeaderJournalOverview,
        JournalIntroAnimations,
        JournalEventTeaser
    },
    props: {
        categorySlug: {
            default: false,
        },
    },
    data() {
        return {
            data: null,
            //stampEntriesData: null,
            metaData: null,
            limit: 12,
            showLoadMore: false,
        };
    },
    computed: {
        ...mapGetters({
            'view':'view/get',
        }),
        globalSiteData() {
            return this.$store.getters['general/get'];
        },
        scrollAnimations() {
           if ( this.globalSiteData && this.globalSiteData.scrollAnimations) {
            if (this.globalSiteData.indexPageEventsUri && this.globalSiteData.indexPageEventsUri.length) {
                const that = this;
                return this.globalSiteData.scrollAnimations.filter((row) => row[2] != that.globalSiteData.indexPageEventsUri);
            } else {
                return this.globalSiteData.scrollAnimations;
            }                
           }
           return null;
        }
    },
    mounted() {
        this.initPageContent();
        this.getJournalEntries();
    },
    unmounted() {
        this.resetPageContent();
    },
    activated() {
        this.initPageContent();
        //this.$store.commit('journalCategories/setCurrentCategorySlug', this.categorySlug);
        if (this.metaData) {
            this.$setHeaderData(this.metaData);
        }
    },
    deactivated() {
        this.resetPageContent();
        //this.$store.commit('journalCategories/setCurrentCategorySlug', false);
    },
    methods: {
        async getJournalEntries() {
            try {
                let response;
                if (this.categorySlug) {
                    response = await Journal.getEntriesByTaxonomy('category', 'journalTagCategories', this.categorySlug);
                } else {
                    //await this.getJournalStampEntries();
                    response = await Journal.getAll();
                }

                this.data = response.data.data;
                this.metaData = response.data.meta;
                // set header data
                this.$setHeaderData(this.metaData);
                this.showLoadMore = true;
            } catch {
                /**/
            }
        },
        /*async getJournalStampEntries() {
            try {
                let response;
                response = await Journal.getAllJournalEvents();

                this.stampEntriesData = response.data.data;
            } catch {
                //
            }
        },*/
        initPageContent() {
            this.$store.commit('view/setPageClass', 'theme-dark');
        },
        resetPageContent() {
            //document.body.classList.remove('theme-dark');
        }
    },
};
</script>

<template>
    <header class="site-header fixed-top" id="site-header">
        <div class="main-navbar d-flex align-items-center container-fluid relative">
            <h1 class="navbar-brand flex-grow-1">
                <router-link v-if="globalSiteData" :to="{ name: 'JournalOverview', params: { locale: this.$route.params.locale } }">{{ globalSiteData.siteName }}</router-link>
            </h1>
            <router-link :to="{name: 'Search', params: { locale: this.$route.params.locale } }" class="search-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.117" height="20.117" viewBox="0 0 20.117 20.117"><g transform="translate(-1283 -15)"><g transform="translate(1283 15)" fill="none" stroke="currentColor" stroke-width="1.8"><circle cx="8.086" cy="8.086" r="8.086" stroke="none"/><circle cx="8.086" cy="8.086" r="7.186" fill="none"/></g><line x2="5.881" y2="5.881" transform="translate(1296.6 28.6)" fill="none" stroke="currentColor" stroke-width="1.8"/></g></svg>
            </router-link>
            <button class="flex-grow-0 language-pdf-dropdown-toggle" @click="toggleLanguagePDFDropdown()" :class="{'dropped-down': showLanguagePDFDropdown}">DE</button>
            <button class="flex-grow-0 ml-auto menu-toggle menu-open" @click="toggleMenu()">{{ $tc('siteHeader.menuButton.open') }}</button>
        </div>
        <transition name="slideToggleMenu">
            <div v-if="showMenu" class="main-navigation-overlay container-fluid position-fixed top-0 right-0 left-0 bg-black text-white">
                <div class="main-navigation-overlay-inner d-flex flex-column">
                    <button class="menu-toggle menu-close" @click="toggleMenu()">{{ $tc('siteHeader.menuButton.close') }}</button>
                    <MainNavigation @close-menu="toggleMenu" />
                    <nav class="secondary-navigation row mt-auto align-items-end">
                        <ul class="col12 text-right meta-navigation">
                            <li><router-link :to="{ name: 'Page', params: { pathArr: ['newsletter'], locale: this.$route.params.locale } }" title="Newsletter" @click="toggleMenu">Newsletter</router-link></li>
                            <li v-if="globalSiteData && (globalSiteData.linkedin || globalSiteData.twitter)"><a v-if="globalSiteData.linkedin" :href="globalSiteData.linkedin" target="_blank">Linkedin</a><template v-if="globalSiteData && (globalSiteData.linkedin && globalSiteData.twitter)"> | </template><a v-if="globalSiteData.twitter" :href="globalSiteData.twitter" target="_blank">Twitter</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </transition>
        <transition name="slideToggleLanguageDropdown">
            <div v-if="showLanguagePDFDropdown && globalSiteData" class="slideToggleLanguageDropdown-transition language-pdf-dropdown container-fluid position-absolute right-0 left-0 bg-light-gray text-black">
                <div class="row">
                    <div class="col-6 language-pdf-text-col" v-for="(languagePDFText, key) in globalSiteData.languagePDFTexts" :key="key">
                        <h6 v-html="languagePDFText.heading"></h6>
                        <div v-html="languagePDFText.text"></div>
                    </div>
                </div>
            </div>
        </transition>
    </header>
</template>

<script>
import { mapGetters } from 'vuex';
//import onFoundation from "./previewBlocks/animationContent/mixins/onFoundation.js";
import MainNavigation from '@/components/MainNavigation';
export default {
    name: 'SiteHeader',
    components: {
        MainNavigation,
    },
    //mixins: [onFoundation],
    props: ['scroll'],
    data() {
        return {
            scrolledDown: false,
            mobileBreakpoint: 576,
            showMenu: false,
            showLanguagePDFDropdown:false,
            supportPageOffset: window.pageXOffset !== undefined,
            isCSS1Compat: (document.compatMode || '') === 'CSS1Compat',
            coveringHeaderOffsetElement: null,
            s: '',
            sShow: false,
        };
    },
    computed: {
        ...mapGetters({
            view: 'view/get',
        }),
        globalSiteData() {
            return this.$store.getters['general/get'];
        },
    },
    mounted() {
        window.addEventListener(
            'scroll',
            () => {
                var coveringHeaderOffsetElement = document.getElementsByClassName('covering-header-offset-element')[0] || false;
                var coveringHeaderOffset = coveringHeaderOffsetElement ? coveringHeaderOffsetElement.clientHeight : 0;
                if (this.getScrollTop() >= coveringHeaderOffset - this.$el.clientHeight) {
                    this.$el.classList.add('covering');
                } else {
                    this.$el.classList.remove('covering');
                }
                this.closeLanguagePDFDropdown();
            },
            true
        );
    },
    methods: {
        send(e) {
            e.preventDefault();
            this.$push(`suche/${this.s}`);
            this.toggleMenu();
            this.sShow = false;
            this.s = '';
        },
        showSearch() {
            this.sShow = true;
            this.$nextTick(() => {
                this.$refs.s.focus();
            });
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        toggleLanguagePDFDropdown() {
            this.showLanguagePDFDropdown = !this.showLanguagePDFDropdown;
        },
        closeLanguagePDFDropdown() {
            this.showLanguagePDFDropdown = false;
        },
        getScrollTop() {
            return this.supportPageOffset ? window.pageYOffset : this.isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
        },
    },
};
</script>

<template>
    <div class="container-fluid entry-header content-blocks content-blocks-centered journal-entry-header d-flex flex-column covering-header-offset-element" :class="{ cover: animateContent && !showAnimatedContent, animated: animateContent }">
        <div class="text-heading-md position-absolute animation-heading">
            <h1 class="entry-title" v-html="entry.title"></h1>
        </div>
    </div>
</template>

<script>
export default {
    name: 'JournalEntryHeader',
    components: {},
    props: {
        entry: {
            default: null,
        },
        animateContent: {
            default: false,
        },
        showContent: {
            default: true,
        },
    },
    data() {
        return {
            animationInterval: false,
            showAnimatedContent: false,
        };
    },
    computed: {
        /*entryUrl() {
            return window.location.origin + this.$route.fullPath;
        },*/
    },
    watch: {
        showContent: {
            handler(newValue) {
                if (newValue && this.animateContent) {
                    this.startTitleAnimation();
                }
            },
            //immediate: true,
        },
    },
    mounted() {
        if (!this.animateContent) {
            this.showAnimatedContent = false;
        } else if (this.showContent) {
            this.startTitleAnimation();
        }
    },
    unmounted() {
        this.showAnimatedContent = false;
    },
    activated() {
        if (!this.animateContent) {
            this.showAnimatedContent = false;
        } else if (this.showContent) {
            this.startTitleAnimation();
        }
    },
    deactivated() {
        this.showAnimatedContent = false;
    },
    methods: {
        startTitleAnimation() {
            var that = this;
            setTimeout(function () {
                //showAnimatedContent needs timeout, otherwise no animation on reactivate
                that.showAnimatedContent = true;
            }, 30);
        },
    },
};
</script>

<template>
    <footer v-if="globalSiteData" class="site-footer text-sm container-fluid" :class="$route.name === 'JournalEntry' || ($route.name === 'JournalOverview' && 'bg-black')">
        <div class="row">
            <div class="col-6 col-md-3">
                <div class="home-nav-item w-100 footer-col col">
                    <div v-if="globalSiteData.footerBlockOne" v-html="globalSiteData.footerBlockOne"></div>
                </div>
            </div>
            <div class="col-6 col-md-3">
                <div class="col footer-col">
                    <div v-if="globalSiteData.footerBlockTwo" v-html="globalSiteData.footerBlockTwo"></div>
                </div>
            </div>
            <div class="col-6 ml-auto col-md-6 d-flex flex-column justify-content-end">
                <nav class="col footer-col footer-navigation d-flex flex-column justify-content-end">
                    <ul class="footer-nav-links d-flex flex-column justify-content-end" v-if="globalSiteData.footerNavigation">
                        <li class="nav-item" v-if="showLangSwitcher"><router-link :to="{ name: 'JournalOverview', params: { locale: '' } }" title="Deutsch" @click="toggleMenu" :class="this.$route.params.locale || 'de' == 'de' ? 'active' : ''">Deutsch</router-link> | <router-link :to="{ name: 'JournalOverview', params: { locale: 'fr' } }" title="Français" @click="toggleMenu" :class="this.$route.params.locale == 'fr' ? 'active' : ''">Français</router-link></li>
                        <li class="nav-item" v-for="(item, index) in globalSiteData.footerNavigation" :key="'link-' + index">
                            <router-link class="nav-link" :to="'/' + $url(item.link)">{{ item.title }}</router-link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'SiteFooter',
    components: {},
    data() {
        return {
            showLangSwitcher: false
        };
    },
    computed: {
        globalSiteData() {
            return this.$store.getters['general/get'];
        },
    },
    mounted() {},
    methods: {},
};
</script>

import { createRouter, createWebHistory } from 'vue-router'
import { i18n, defaultLocale } from '../i18n'

//Views
import Page from '../views/Page'
import PageNotFound from '../views/PageNotFound'
import JournalOverview from '@/views/JournalOverview'
import JournalCategoryArchive from '@/views/JournalCategoryArchive'
import JournalEntry from '@/views/JournalEntry'
import EventEntry from '@/views/EventEntry'
import CoachingEntry from '@/views/CoachingEntry'
import FeaturedProjectEntry from '@/views/FeaturedProjectEntry'
import Search from '@/views/Search';
import NewsletterSubscriptionVerificationSuccess from '@/views/NewsletterSubscriptionVerificationSuccess'
import NewsletterUnsubscriptionSuccess from '@/views/NewsletterUnsubscriptionSuccess'

const routes = [
  {
    path: '/:locale(de)?/', //path: '/:locale(fr|de)?/',
    name: 'JournalOverview',
    components: {
        default: JournalOverview,
    },
  },
  {
    path: '/:locale(de)?/stiftung/newsletter/anmeldung-bestaetigen', // path: '/:locale(fr|de)?/stiftung/newsletter/anmeldung-bestaetigen',
    name: 'NewsletterSubscriptionVerificationSuccess',
    components: {
        default: NewsletterSubscriptionVerificationSuccess
    },
  },
  {
    path: '/:locale(de)?/stiftung/newsletter/abmelden', //path: '/:locale(fr|de)?/stiftung/newsletter/abmelden',
    name: 'NewsletterUnsubscriptionSuccess',
    components: {
        default: NewsletterUnsubscriptionSuccess
    },
  },
  {
    path: '/:locale(de)?/suche/:search?', //path: '/:locale(fr|de)?/suche/:search?',
    name: 'Search',
    components: {
        default: Search
    }
  },
  {
    path: '/:locale(de)?/journal/tag/:categorySlug', //path: '/:locale(fr|de)?/journal/tag/:categorySlug',
    name: 'JournalCategoryArchive',
    props: true,
    components: {
        default: JournalCategoryArchive,
    },
  },
  {
    path: '/:locale(de)?/journal/:slug', //path: '/:locale(fr|de)?/journal/:slug',
    name: 'JournalEntry',
    components: {
        default: JournalEntry,
    },
  },
  {
    path: '/:locale(de)?/veranstaltungen/:slug', //path: '/:locale(fr|de)?/journal/:slug',
    name: 'EventEntry',
    components: {
        default: EventEntry,
    },
  },
  {
    path: '/:locale(de)?/coaching/:slug', //path: '/:locale(fr|de)?/coaching/:slug',
    name: 'CoachingEntry',
    components: {
        default: CoachingEntry,
    },
  },
  {
    path: '/:locale(de)?/projekte/:slug', //path: '/:locale(fr|de)?/projekte/:slug',
    //alias: '/:locale(fr|de)?/projects/:slug',
    name: 'FeaturedProjectEntry',
    components: {
        default: FeaturedProjectEntry,
    },
  },
  {
    path: '/fr',
    alias: '/fr/:pathArr+',
    name: 'PageNotFound',
    components: {
        default: PageNotFound,
    },
  },

  {
    path: '/:locale(de)?/:pathArr+', //path: '/:locale(fr|de)?/:pathArr+',
    name: 'Page',
    components: {
        default: Page
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
         to.meta.scrollPosition = savedPosition;
          //return savedPosition
      } else {
          to.meta.scrollPosition = { left: 0, top: 0 };
      }
  }
})

router.beforeEach((to, from , next) => {
  if (to.params.locale === from.params.locale) {
      next()
      return
  }

  const lang = to.params.locale || defaultLocale
  i18n.global.locale = lang

  next();
});

export default router

<template>
    <component :is="isLink ? 'router-link' : 'div'" :to="isLink ? { name: 'EventEntry', params: { slug: entry.slug, locale: $route.params.locale || '' } } : null" class="block-preview-journal-event-teaser container-fluid" :class="entryThemeClass" :style="cssStyle">
        <div class="row">
            <div class="col-12 col-md-2 entry-preview-date" v-html="entry.date"></div>
            <h2 class="col-12 col-md-8 entry-preview-title" v-html="entry.title"></h2>
            <h5 class="col-12 col-md-2 entry-preview-subtitle">
                <span v-if="entry.eventType && entry.eventType.length" v-html="entry.eventType"></span>
                <span v-if="entry.callToActionText" v-html="entry.callToActionText"></span>
            </h5>
        </div>
    </component>
</template>

<script>
export default {
    name: "PreviewBlockJournalEventTeaser",
    props: ["entry"],
    data() {
        return {};
    },
    computed: {
        isLink() {
            return this.entry.slug && this.entry.slug.length;
        },
        cssStyle() {
            if (this.entry && this.entry.theme) {
                const secondaryColor = this.entry.theme.secondaryColor || '#FFF';
                return {
                    '--theme-primary-color': this.entry.theme.primaryColor || '#000',
                    '--theme-secondary-color': secondaryColor
                }
            } else {
                return null;
            }
        },
        entryThemeClass() {
            let themeClass = 'theme-default';
            if (this.entry && this.entry.theme) {
                themeClass = 'theme-custom-colors';
            }
            return themeClass;
        },
    },
    methods: {},
};
</script>

export default {
    data(){
        return {
            //internalLinksInited: false,
        }
    },
    methods: {
        initInternalLinks(context) {
            //if (context && !this.internalLinksInited) {
            if (context) {
                this.internalLinksInited = true;
                const entryContentLinks = context.getElementsByTagName('a');
                const apiLocation = new URL(process.env.VUE_APP_API_URL);
                const appLocation = window.location;
                for (var i = 0; i < entryContentLinks.length; i++) {
                    const link = entryContentLinks[i];
                    if (link.hostname == apiLocation.hostname && this.isFolderPath(link.pathname)) {
                        link.href = link.href.replace(apiLocation.origin, appLocation.origin);// link target _blank
                        link.addEventListener('click', this.onInternalLinkClicked); // link target same window
                    }
                }
            }
        },
        onInternalLinkClicked(e) {
            if (e.target.getAttribute('target') !== '_blank') {
                e.preventDefault();
                this.$router.push(e.target.pathname+e.target.search);
            }
        },
        isFolderPath(pathname) {
            return pathname.split('/').pop().indexOf('.') < 0;
        }
    }
}

import { getHttp } from './fetchApi';

export const Journal = {
    URL: '/journal',

    getAll(){
        return getHttp(`${this.URL}.json`)
    },

    get(slug){
        return getHttp(`${this.URL}/${slug}.json`)
    },

    getEntriesByTaxonomy(elementType, groupHandle, slug) {
        return getHttp(`${this.URL}/${elementType}/${groupHandle}/${slug}.json`)
    },

    getAllJournalEvents() {
        return getHttp(`${this.URL}/journal-events.json`)
    },

}

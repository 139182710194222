<template>
    <div class="block block-text-standard block-text-lead container-fluid">
        <div class="row content-block-row">
            <div class="col-12 text-lead" v-html="block.text"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'textLead',
    props: ['block'],
}
</script>
